import { render, staticRenderFns } from "./FrameworkTem.vue?vue&type=template&id=8376a786"
import script from "./FrameworkTem.vue?vue&type=script&lang=js"
export * from "./FrameworkTem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/assets/styles/views/FrameworkTem.css?vue&type=custom&index=0&blockType=styles&issuerPath=%2Fapp%2Fsrc%2Fviews%2FFrameworkTem.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports