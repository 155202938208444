<template>
  <div>
    <main id="framework">
      <div id="nav">
      </div>
      <section id="page-title">
        <row>
          <column :lg="8" class="page-title">
            <div align="left" class="back">
              <router-link to="/">
                <img src="../../src/assets/BackArrow.svg" alt="back-arrow" /> Back
              </router-link>
            </div>

            <div classhref="#" tabindex="0" class="popover-style">
              <Bpopover1 :info="info1" idPopover="pb1" />
            </div>

            <h1 class="title">SDG 4 - Quality Education</h1>
          </column>
          <column :lg="4" class="progress-summary">
            <div
              classhref="#"
              tabindex="0"
              title=""
              class="popover-style bpopover2-container"
            >
              <Bpopover2 :info="info2" idPopover="2PB" id="2pbC" />
            </div>

            <div class="doughnut-1">
              <aim-doughnut-chart
                :doughnutChartData="doughnutChartData1"
              ></aim-doughnut-chart>
            </div>
            <div class="doughnut-2">
              <time-doughnut-chart
                :doughnutChartData="doughnutChartData2"
              ></time-doughnut-chart>
            </div>
          </column>
        </row>
      </section>
      <section class="framework-select-area">
        <row :gutter="12">
          <column :lg="1.5">
            <h3 class="framework-select-country">Select type</h3>
          </column>
          <column :lg="2.5" class="framework-select-box">
            <v-select
              :options="moneyTypes"
              v-model="moneyTypeSelected"
              placeholder="Show all"
              :searchable="false"
              :clearable="false"
            >
            </v-select>
          </column>
        </row>
      </section>
      <section :gutter="12" class="chart-title-area">
        <row class="chart-title">
          <column :lg="8" :xs="6">
            <h2 class="framework-sub-title">
              Number of students for SDG 4 - across countries
            </h2>
          </column>
          <column class="chart-summary" :lg="4" :xs="6">
            <div class="total-money">
              <span>
                <h1>+{{ totalTenants }} students</h1>
              </span>
              <h3 class="source-sans-font">after INS</h3>
            </div>
            <div class="growth-rate">
              <h1>{{ growthRate }}</h1>
              <h3 class="source-sans-font">after INS</h3>
            </div>
          </column>
        </row>
      </section>
      <section id="chart-area">
        <row :gutter="12" class="chart-main">
          <column :lg="6" class="douhnut-chart-area">
            <h2>{{ selectedTypeData.achievedDoughnutChartData.title }}</h2>
            <framework-doughnut-chart
              :doughnutChartData="selectedTypeData.achievedDoughnutChartData"
              :year="selectedYear1"
            ></framework-doughnut-chart>
            <column :lg="4" :xs="12" class="year-select-box"
              >in
              <v-select
                :options="yearOptions"
                v-model="selectedYear1"
                class="select-year"
                placeholder="Show all"
                :searchable="false"
                :clearable="false"
              ></v-select
            ></column>
          </column>
          <column :lg="6" class="douhnut-chart-area">
            <h2>{{ selectedTypeData.aimDoughnutChartData.title }}</h2>
            <framework-doughnut-chart
              :doughnutChartData="selectedTypeData.aimDoughnutChartData"
              :year="selectedYear2"
            ></framework-doughnut-chart>
            <column :lg="4" :xs="12" class="year-select-box"
              >in
              <v-select
                :options="yearOptions"
                v-model="selectedYear2"
                class="select-year"
                placeholder="Show all"
                :searchable="false"
                :clearable="false"
              ></v-select
            ></column>
          </column>
        </row>
      </section>
    </main>
  </div>
</template>

<script>
import AimDoughnutChart from "../components/Chart/AimDoughnutChart.vue";
import TimeDoughnutChart from "../components/Chart/TimeDoughnutChart.vue";
import FrameworkDoughnutChart from "../components/Chart/FrameworkDoughnutChart";
import Bpopover1 from "../components/B-popover.vue";
import Bpopover2 from "../components/B-popover.vue";

export default {
  components: {
    AimDoughnutChart,
    TimeDoughnutChart,
    Bpopover1,
    Bpopover2,
    FrameworkDoughnutChart,
},
  data() {
    return {
      selectedYear1: 2022,
      selectedYear2: 2022,
      info1: `In this demo project the social enterprise offers digital education to students in refugee camps in 6 african countries. Measuring the programme’s success against SDG 4 -Quality Education- we present how many refugee students were helped into school, out of the total number of refugee children out of school, worldwide. `,
      info2: `If you run more than one programmes as 
              a fund, foundation, charity or sustainable 
              corporation, you can navigate through the 
              dashboards of each programme, from this 
              drop down selection.`,
      doughnutChartData1: {
        cssId: "box1",
        title: "Aim",
        subtitle1: "£50K",
        subtitle2: "/year",
        subtitle2Position: [36, 36.2, 40],
        subtitle3: "by Resonance",
        percentage: "20",
        insideText: "complete",
        color: "#8954BA",
      },
      doughnutChartData2: {
        box: "box2",
        title: "Time",
        subtitle1: "8 years",
        subtitle2: "",
        percentage: "5",
        insideText: "more year",
        color: "#0091FF",
        totalYear: 8,
      },
      yearOptions: [2019, 2020, 2021, 2022],
      moneyTypes: [
        "Money offered to tenants",
        "Money saved by tenants",
        "Money spent by tenants",
      ],
      moneyTypeSelected: "Money offered to tenants",
      totalTenants: "32,000",
      growthRate: "+56%",
      dataExamples: {
        "Money offered to tenants": {
          achievedDoughnutChartData: {
            title: "Achieved - 86K students",
            canvasId: "achieved",
            firstlinebold: "3.7m",
            firstlinelight: "refugee children out of school",
            firstlinePosition: [6, 11, 500, 45, 11, 500],
            secondlinebold: "57K",
            secondlinelight: "boys helped into school",
            secondlinePostiion: [7, 43, 500, 45, 43, 500],
            thirdlinebold: "29K",
            thirdlinelight: "girls helped into school",
            thirdlinePostiion: [15, 75, 500, 45, 75, 500],
            percentageForChart: [79, 30, 23], // out / middle / inside
            percentage: "2",
            percentageTextPostiion: [180, 160, 500],
            insideText: "contribution",
            insideTextPostiion: [200, 190, 100],
            centerX: 250,
            centerY: 163.5,
          },
          aimDoughnutChartData: {
            title: "Aimed - 500K students",
            canvasId: "aim",
            firstlinebold: "3.7m",
            firstlinelight: "refugee children out of school",
            firstlinePosition: [2, 11, 500, 50, 11, 500],
            secondlinebold: "300K",
            secondlinelight: "boys helped into school",
            secondlinePostiion: [10, 43, 500, 50, 43, 500],
            thirdlinebold: "200K",
            thirdlinelight: "girls helped into school",
            thirdlinePostiion: [10, 75, 500, 50, 75, 500],
            percentageForChart: [60, 30, 40], // out / middle / inside
            percentage: "13",
            percentageTextPostiion: [250, 160, 500],
            insideText: "contribution",
            insideTextPostiion: [255, 190, 100],
            centerX: 291.66,
            centerY: 163.5,
          },
        },
        "Money saved by tenants": {
          achievedDoughnutChartData: {
            title: "Achieved - 105K students",
            canvasId: "achieved",
            firstlinebold: "2.3m",
            firstlinelight: "refugee children out of school",
            firstlinePosition: [6, 11, 500, 45, 11, 500],
            secondlinebold: "45K",
            secondlinelight: "boys helped into school",
            secondlinePostiion: [7, 43, 500, 45, 43, 500],
            thirdlinebold: "25K",
            thirdlinelight: "girls helped into school",
            thirdlinePostiion: [15, 75, 500, 45, 75, 500],
            percentageForChart: [80, 83, 28], // out / middle / inside
            percentage: "15",
            percentageTextPostiion: [180, 160, 500],
            insideText: "contribution",
            insideTextPostiion: [200, 190, 100],
            centerX: 250,
            centerY: 163.5,
          },
          aimDoughnutChartData: {
            title: "Aimed - 440K students",
            canvasId: "aim",
            firstlinebold: "2.3m",
            firstlinelight: "refugee children out of school",
            firstlinePosition: [2, 11, 500, 50, 11, 500],
            secondlinebold: "2000K",
            secondlinelight: "boys helped into school",
            secondlinePostiion: [10, 43, 500, 50, 43, 500],
            thirdlinebold: "150K",
            thirdlinelight: "girls helped into school",
            thirdlinePostiion: [10, 75, 500, 50, 75, 500],
            percentageForChart: [75, 54, 32], // out / middle / inside
            percentage: "26",
            percentageTextPostiion: [250, 160, 500],
            insideText: "contribution",
            insideTextPostiion: [255, 190, 100],
            centerX: 291.66,
            centerY: 163.5,
          },
        },
        "Money spent by tenants": {
          achievedDoughnutChartData: {
            title: "Achieved - 45K students",
            canvasId: "achieved",
            firstlinebold: "1.9m",
            firstlinelight: "refugee children out of school",
            firstlinePosition: [6, 11, 500, 45, 11, 500],
            secondlinebold: "57K",
            secondlinelight: "boys helped into school",
            secondlinePostiion: [7, 43, 500, 45, 43, 500],
            thirdlinebold: "29K",
            thirdlinelight: "girls helped into school",
            thirdlinePostiion: [15, 75, 500, 45, 75, 500],
            percentageForChart: [10, 75, 78], // out / middle / inside
            percentage: "33",
            percentageTextPostiion: [180, 160, 500],
            insideText: "contribution",
            insideTextPostiion: [200, 190, 100],
            centerX: 250,
            centerY: 163.5,
          },
          aimDoughnutChartData: {
            title: "Aimed - 400K students",
            canvasId: "aim",
            firstlinebold: "1.5m",
            firstlinelight: "refugee children out of school",
            firstlinePosition: [2, 11, 500, 50, 11, 500],
            secondlinebold: "160K",
            secondlinelight: "boys helped into school",
            secondlinePostiion: [10, 43, 500, 50, 43, 500],
            thirdlinebold: "105K",
            thirdlinelight: "girls helped into school",
            thirdlinePostiion: [10, 75, 500, 50, 75, 500],
            percentageForChart: [70, 45, 28], // out / middle / inside
            percentage: "45",
            percentageTextPostiion: [250, 160, 500],
            insideText: "contribution",
            insideTextPostiion: [255, 190, 100],
            centerX: 291.66,
            centerY: 163.5,
          },
        },
      },
    };
  },
  mounted() {
    this.showNavBar();
  },
  methods: {
    showNavBar() {
      const navbar = document.getElementById("nav");
      navbar.style.display = "inline";
      const sidebar = document.getElementById("sidebar");
      sidebar.style.display = "block";
    },
  },
  computed: {
    selectedTypeData() {
      return this.dataExamples[this.moneyTypeSelected];
    },
  },
};
</script>

<styles src="@/assets/styles/views/FrameworkTem.css"></styles>
